@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;500;600;700&display=swap');

/* Tailwindcss classes */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */


/* @import '~quill/dist/quill.bubble.css'; */

:root {
    /* Color styles */
    --color-white: #ffffff;
    --color-white-dark: #f1f2f5;
    --color-cyan: #4cc0c1;
    --shadow-1: #000000;
    --shadow-2: #00000022;
    --error-1: #cc2c2c;
    --error-2: #ff5855;
    /* tailwind styles */
    /* primary #4d5d76 */
    --color-primary: 77 93 118;
    /* primary-old #24303E */
    --color-primary-old: 36 48 62;
    /* secondary #4787F3 */
    --color-secondary: 71 135 243;
    /* tertiary #F95959 */
    --color-tertiary: 249 89 89;
    /* white #FCFCFC */
    --color-white-light: 255 255 255;
    /* white #E0E0E0 */
    --color-white-dark: 224 224 224;
    /* black #292929 */
    --color-black: 41 41 41;
    /* success #2ECF8B */
    --color-success: 46 207 139;
    /* info #009DC4 */
    --color-info: 0 157 196;
    /* warning #F7C02B */
    --color-warning: 247 192 43;
    /* error #D5390D */
    --color-error: 189 43 71;
    /* blue #4787F31A */
    --color-blue: 71 135 243;
    /* summary #F2F2F2 */
    --color-summary: 242 242 242;
    /* Chart color #F8F9FD */
    --color-chart: 242 242 242;
    /* Chart icon label color #4787F3 */
    --color-icon-label: 71 135 243;
    /* default text size */
    --font-size: 11px;
}

.my-custom-fullscreen {
    position: fixed;
    top: 5%;
    left: 30%;
    width: 50vw;
    /* height: 70vh; */
    background-color: white;
    z-index: 8888;
    padding: 1.25rem;
    /* overflow-y: auto; */
}

.wrapper-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.32);
    z-index: 8887;
    opacity: 0.5;
}

.chart-wrapper .mat-slide-toggle-thumb {
    @apply bg-chartIconLabel
}

.chart-wrapper .mat-slide-toggle-bar {
    @apply bg-black/10
}

.removeMatWrapperPadding .mat-form-field-wrapper {
    @apply !p-0
}

.removeInputPadding .mat-form-field-infix {
    @apply p-0 pb-1
}

.removeInputPadding .mat-form-field-suffix {
    @apply !top-[0.15em] left-1 pl-0
}

.slick-prev:before,
.slick-next:before {
    @apply !text-primary
}

.slick-next {
    @apply !-right-5
}

.slick-prev {
    @apply !-left-5
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    /* Hide the scrollbar for Firefox */
    scrollbar-width: none;
    /* Hide the scrollbar for IE, Edge, and other WebKit-based browsers */
    -ms-overflow-style: none;
}

.mat-calendar-previous-button,
.mat-calendar-next-button {
    height: 40px !important;
}

.mat-step-header:hover {
    background-color: transparent !important;
}

.mat-step-header.cdk-program-focused {
    background-color: transparent;
}

.mat-step-header.cdk-mouse-focused {
    background-color: transparent;
}

.mat-step-header.cdk-focused {
    background-color: transparent;
}

.mat-step-header .mat-step-icon-state-edit {
    @apply bg-success;
}

.mat-step-header .mat-step-icon-selected {
    @apply bg-primary;
}

.mat-step-header .mat-step-icon:not(.mat-step-icon-selected,
.mat-step-icon-state-edit) {
    @apply bg-slate-100 text-black;
}

* {
    font-family: 'Lexend Deca', sans-serif;
}

input,
select,
textarea,
button {
    border-radius: 2px !important;
}

button {
    height: 35px !important;
}

.mat-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 2px !important;
}

.mat-form-field .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 2px !important;
}

body {
    font-size: var(--font-size);
    color: #717171;
}

.sort-btn {
    @apply border-[#3b3b3b] w-max p-[5px] border-[1px] rounded-lg flex items-center justify-evenly !h-[32px] mt-[2.3px] bg-chart;
}

.entry-dialog.new-client {
    @apply lg:!w-[850px] lg:!h-auto lg:max-w-[80vw] sm:!w-full sm:!h-full sm:!max-w-full;
}

.entry-dialog .mat-dialog-container,
.ledger-entry .mat-dialog-container {
    @apply !p-0 !m-0;
    /* !ml-[20%] */
}

.entry-dialog .dialog-body {
    @apply p-2 lg:!p-5;
}

.mat-horizontal-content-container {
    @apply sm:!px-[10px]
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    @apply sm:!px-[10px]
}

.ledger-entry-checkbox .mat-checkbox-layout {
    @apply flex items-start gap-2;
}

.ledger-entry-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
    @apply m-0;
}

.ledger-entry-checkbox .mat-checkbox-layout .mat-checkbox-label {
    @apply leading-5;
}

.highlight-mat-input input:disabled,
.highlight-mat-input .mat-input-element:disabled,
.highlight-mat-input .mat-select-disabled .mat-select-value,
.highlight-mat-input .mat-select-value-text {
    @apply !text-secondary
}

.highlight-mat-input-error .mat-form-field-outline {
    @apply !text-secondary
}

.highlight-mat-input-error .mat-form-field-outline-start,
.highlight-mat-input-error .mat-form-field-outline-end,
.highlight-mat-input-error .mat-form-field-outline-gap {
    @apply !border-2 border-red-600
}

.highlight-mat-input-error .mat-form-field-label {
    @apply !text-red-600
}

input#country-search-box {
    padding: 10px;
}

.cdk-overlay-container {
    z-index: 3000;
}

.modal-positioning {
    margin-left: 15.5em;
    margin-top: 5em;
}

.modal-product {
    margin-left: 15.5em;
    margin-top: 5em;
}

.modal-product .mat-dialog-container {
    padding: 0;
}


/* 
.material-icons {
    line-height: unset;
} */

.cdk-drag,
.cdk-drag-handle {
    @apply cursor-grab;
}

.filter-primary {
    filter: invert(17%) sepia(12%) saturate(1315%) hue-rotate(172deg) brightness(25%) contrast(95%);
}

.filter-white {
    filter: invert(95%) sepia(59%) saturate(26%) hue-rotate(72deg) brightness(107%) contrast(98%);
}

.contract .angular-editor-textarea {
    background-color: white !important;
}


/* svg {
    @apply text-primary
} */

.active svg {
    @apply text-white;
}

.exclude svg {
    color: inherit;
}

.action {
    text-transform: capitalize;
    border: 1px solid rgba(0, 0, 0, 0.213);
    border-radius: 0.3em;
    padding: 10px 15px;
    margin: 0 3px;
}

.darkButton {
    background-color: #24313e;
    color: white;
}

.darkOutlineButton {
    border-color: #24313e;
    background: transparent;
    color: black;
}

@media (min-width: 720px) {
    .contract .angular-editor-textarea {
        background-color: white !important;
    }
    .contract {
        width: 80%;
    }
}


/* .slick-slide {
    width: 130px !important;
} */

.light-green {
    background-color: #dff0d8;
}

.light-red {
    background-color: #f2dede;
}

.angular-editor-button {
    font-size: 15px;
}


/* 
table {
    width: 100%;
    table-layout: fixed;
} */

#grid th,
#grid td {
    overflow: hidden;
    /* width: 180px !important; */
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 20px !important;
    cursor: pointer;
    /* border-bottom: none; */
}

#grid2 th,
#grid2 td {
    /* width: 180px !important; */
    white-space: nowrap;
    padding: 3px 8px !important;
    cursor: pointer;
    /* border-bottom: none; */
}

.bg-error {
    background-color: var(--error-1);
}

.bg-error-light {
    background-color: var(--error-2);
}

.error {
    color: var(--error-1);
}

.w-sm {
    width: 80px;
    height: 80px;
}

.rounded-circle {
    border-radius: 50%;
}

.rounded-circle-15 {
    border-radius: 1.5em;
}

.rounded-left-07 {
    border-top-left-radius: 0.7em;
    border-bottom-left-radius: 0.7em;
}

.rounded-right-07 {
    border-top-right-radius: 0.7em;
    border-bottom-right-radius: 0.7em;
}

.rounded-left-15 {
    border-top-left-radius: 1.5em;
    border-bottom-left-radius: 1.5em;
}

.bg-success-dark {
    background-color: green;
}

.rounded-right-15 {
    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
}

.border-none {
    border: none;
}

.bg-grey-dark {
    background-color: #23303f;
}

.bg-grey-light {
    background-color: rgb(233, 233, 233);
}

ul {
    padding: 0;
}

.stepperBtn {
    background-color: transparent;
    color: var(--shadow-1);
    border: 1px solid rgba(0, 0, 0, 0.42);
    transition: 0.2s all;
    opacity: 0.6;
}

.stepperBtn:hover {
    transform: scale(1.02);
    box-shadow: 0px 15px 10px -10px var(--shadow-2);
    opacity: 0.9;
}

.w-100px {
    width: 100px;
}

.w-120px {
    width: 120px;
}

.h-35px {
    height: 35px;
}

.nav-sub-menu {
    padding-inline-start: 10px;
}

.client .mat-horizontal-content-container {
    overflow-y: auto;
    padding: 0 24px 24px 24px;
    height: 68vh;
}

.ant-picker {
    border: 1px solid rgba(0, 0, 0, 1.711);
    border-radius: 0.3em;
    height: 51.59px;
}

.mat-sort-header-arrow {
    opacity: 0.54 !important;
    transform: translateY(0px) !important;
}

.mat-sort-header-sorted .mat-sort-header-arrow {
    color: blue !important;
}

.mat-row:nth-child(even) {
    background-color: #ffffff;
}

.mat-row:nth-child(odd) {
    background-color: #f8f9fd;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

button[disabled],
html input[disabled],
textarea[disabled],
.mat-select-disabled .mat-select-value {
    opacity: 0.6;
    cursor: not-allowed !important;
}

.mat-select-value {
    color: #717171;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.711);
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline,
.mat-input-element:disabled,
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label,
.mat-select-disabled .mat-select-value,
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow,
.mat-icon-button.mat-button-disabled.mat-button-disabled {
    color: rgba(0, 0, 0, 0.5);
}

.chart-input .mat-form-field-outline {
    @apply !bg-chart;
}

.mat-select-search-inner .mat-icon {
    font-size: 15px;
    margin-bottom: 8px;
}

.mat-form-field-disabled {
    opacity: 0.8 !important;
}

.mat-sort-header-stem,
.mat-sort-header-pointer-left,
.mat-sort-header-pointer-right,
.mat-sort-header-pointer-middle {
    display: none !important;
}

.mat-sort-header-indicator {
    width: 0;
    height: 10px !important;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid;
    border-top: 0px;
    transform: translateY(0px) !important;
}

th[aria-sort="ascending"] .mat-sort-header-indicator {
    border-bottom: 9px solid;
    border-top: 0px;
}

th[aria-sort="descending"] .mat-sort-header-indicator {
    border-top: 9px solid;
    border-bottom: 0px;
    transform: translateY(3px) !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
}

.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
    height: 35px;
}


/* .removeMatFormPad .mat-form-field-appearance-outline .mat-form-field-wrapper{
    margin: 0;
    padding: 0;
} */

.removePMFromMatForm .mat-form-field-wrapper {
    margin: 0;
    padding: 1px 0;
}


/* .mat-step-header {
    pointer-events: none !important;
} */

.mat-column-checkbox {
    width: 5px !important;
}

.select-input .select .mat-form-field-outline-end {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.select-input .input .mat-form-field-outline-end {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 2px;
}

.phone .mat-form-field-label {
    top: 3em !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 40px;
}

.mat-form-field-flex {
    align-items: center !important;
    height: 35px;
}

.filter-height .mat-form-field-appearance-outline .mat-form-field-flex {
    @apply sm:w-[100px] md:w-[130px]
}

.textarea-input .mat-form-field-wrapper .mat-form-field-flex {
    height: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding-block: 7px;
}

button.mat-menu-item {
    height: 35px;
    font-size: 11px;
    padding: 0 12px;
    line-height: 33px;
}

.mat-menu-panel {
    border-radius: 8px !important;
}

.mat-menu-content:not(:empty) {
    padding: 0 !important;
}

.mat-form-field-suffix {
    padding-left: 6px;
    padding-top: 5px;
}

.mat-form-field-prefix {
    padding-right: 6px;
    padding-top: 5px;
}

.rounded-top-lg {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.rounded-lg {
    border-radius: 2px;
}

.rounded-xl {
    border-radius: 0.8em;
}

.rounded-top-xl {
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;
}

.box-shadow-dark {
    box-shadow: 0 0 1px var(--shadow-2);
}

.box-shadow-bottom-dark {
    box-shadow: 0px 15px 10px -10px var(--shadow-1);
}


/* peek-a-bar */

.peek-a-bar {
    color: #fff;
    z-index: 10000;
}


/* peek-a-bar */

.red {
    color: #b92c28;
}

.green {
    color: #3e8f3e;
}

.bg-light {
    background: #eeeeee;
}


/* form-error */

.form-error {
    float: left;
    color: #d66565;
}


/* form-error */


/* top right btns */

.top-right-btns-container {
    float: right;
    margin-top: 7px;
}

.top-right-btns-container a.btn {
    margin-left: 2px !important;
}


/* forms */

.required-field:after {
    content: " *";
    color: #f25656;
    position: fixed;
    margin-left: 5px;
}

input[type=file],

/* FF, IE7+, chrome (except button) */

input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
}


/* table */

.table {
    width: 100%;
    background-color: #fff;
    border: solid 1px #d9d9d9;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #4ec0c1;
    border-color: #4ec0c1;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    padding: 10px 10px;
}


/*ng2-ya-table*/

.table.ng2-ya-table {}

.table.ng2-ya-table>tbody>tr:first-child {
    display: none;
}

table.wz-check-row {}

table.wz-check-col button.check {
    padding: 0px 3px;
}

table.wz-check-col button.check.checked {
    background-color: #8ec165;
    border-color: #8ec165;
}

table.wz-check-col button.check i.fa {
    color: #fff;
}

table.wz-check-col thead tr th:first-child {
    /*width: 20px;
    padding-right: 0px !important;
    background-color: white;*/
}

table.wz-check-col thead tr th.col-check-btn,
table.wz-check-col tbody tr td.col-check-btn {
    width: 20px !important;
    padding: 4px 5px;
}

table.wz-check-col thead tr th:first-child:before {
    /*content: "\f00c";
    border: solid 1px #ddd;
    border-radius: 3px;
    padding: 0px 7px 3px 6px;
    background-color: #ddd;
    color: #fff;*/
    cursor: pointer;
}

table.ng2-ya-table.wz-check-col thead tr th.sorting_asc:first-child:after,
table.ng2-ya-table.wz-check-col thead tr th.sorting_desc:first-child:after {
    content: "" !important;
}


/* angular datatable */


/*
   server-side-angular-way.component.css
*/

.dataTables_info,
.dataTables_paginate {
    padding: 5px 0px !important;
}

.no-data-available {
    text-align: center;
}


/*
   src/styles.css (i.e. your global style)
*/

.dataTables_empty {
    display: none;
}

table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc {
    /*background-image: none !important;*/
}

.dataTables_wrapper select,
.dataTables_wrapper input {
    font-size: 12px;
    height: 30px;
    padding: 5px 10px;
    border-radius: 3px;
    display: inline;
    border: 1px solid #d9d9d9;
}

.dataTables_wrapper .table {
    border: 1px solid #eee;
    width: 100% !important;
}

table.dataTable.no-footer {
    border-bottom: 1px solid #eee;
}

.dataTables_info,
.dataTables_paginate {
    padding: 15px;
}

.paginate_button.first {
    border-radius: 3px 0 0 3px;
}

.dataTables_wrapper .paginate_button,
.dataTables_wrapper .paginate_active {
    cursor: pointer;
    outline: 0;
    border: 1px solid #d9d9d9;
    border-right-width: 0;
    background: #fff;
    padding: 4px 8px;
}

.paginate_button.last {
    border-radius: 0 3px 3px 0;
    border-right-width: 1px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: -1px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: #333333 !important;
    border: 1px solid #ddd;
    border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #333333 !important;
    border: 1px solid #ddd;
    background: transparent;
    box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #333 !important;
    border: 1px solid #ddd;
    background-color: #ddd;
    background: linear-gradient(to bottom, #ddd 0%, #ddd 100%);
}

.dataTables_paginate .paginate_button:active,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    outline: none;
    background: none;
    box-shadow: none;
    border: solid 1px #ddd;
    color: #333 !important;
    background-color: #eee;
}

.dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.4em;
    padding-right: 0;
}

button.dt-button,
div.dt-button,
a.dt-button {
    border: solid 1px #ddd;
    color: #333333;
    background-image: none;
}

button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover {
    border: solid 1px #ddd;
    color: #333;
}

button.dt-button.btn-danger {
    color: #fff !important;
    background-color: #fb6b5b !important;
    border-color: #fb6b5b !important;
    background-image: none;
}

button.dt-button.btn-danger:hover,
button.dt-button.btn-danger:visited,
button.dt-button.btn-danger:focus,
button.dt-button.btn-danger:active {
    color: #fff !important;
    background-color: #fb6b5b !important;
    border-color: #fb6b5b !important;
    background-image: none !important;
}

button.dt-button.btn-success {
    background-color: #8ec165;
    border-color: #8ec165;
}

button.dt-button.btn-success:hover,
button.dt-button.btn-success:visited,
button.dt-button.btn-success:focus,
button.dt-button.btn-success:active {
    color: #fff !important;
    background-color: #7aa755 !important;
    border-color: #7aa755 !important;
    background-image: none !important;
}


/* hide copy and print btns */

button.dt-button.buttons-copy,
button.dt-button.buttons-print {
    display: none;
}


/* select2 */

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 3px 0px 30px 0px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #4cc0c1 !important;
}

.select2-container--default.select2-container .select2-selection--multiple {
    border-color: #d9d9d9;
    border-radius: 2px;
    padding: 3px;
}

.has-error .select2-container--default .select2-selection--single {
    border: 1px solid #a94442;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 4px;
}

.select2-container {
    width: 100% !important;
}


/* image placeholder */

.img-placeholder img {
    height: 150px;
    width: 150px;
    border: solid 1px #eee;
    border-radius: 10px;
    object-fit: cover;
}

.select-btn-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.custom-file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.select-image-btn {
    border: solid 1px #ddd;
    background-color: #f8f8f8;
    padding: 4px 5px;
    margin: auto;
    cursor: pointer;
    border-radius: 2px;
}

.img-placeholder.id img {
    width: 100%;
    height: 225px;
    border-radius: 2px;
}


/* dcalendar */


/*/Don't remove this!
 * jQuery duDatePicker plugin styles
 *
 * Author: Dionlee Uy
 * Email: dionleeuy@gmail.com
 *
 * Date: Monday, Sept 4 2017
 */

.dcalendarpicker {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(10, 10, 10, 0.7);
    z-index: 100001;
    overflow: hidden;
    transition: background-color 0.2s ease;
}

.dcalendarpicker.dp__animate {
    background-color: transparent;
}

.dcalendarpicker.dp__hidden {
    display: none;
}

.dudp__wrapper {
    font-family: 'Lexend Deca', sans-serif;
    ;
    font-size: 12px;
    line-height: normal;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 50%;
    bottom: 24px;
    min-width: 280px;
    opacity: 1;
    overflow: hidden;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    border-radius: 2px;
    transform: translateX(-50%) scale(1);
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    transition: transform 0.25s ease, opacity 0.25s ease;
}

.dudp__wrapper.dp__animate {
    opacity: 0;
    transform: translateX(-50%) scale(1.05);
}

.dudp__calendar-header {
    font-size: 18px;
    padding: 20px;
    color: rgb(220, 220, 220);
}

.dudp__calendar-header .dudp__sel-year,
.dudp__calendar-header .dcp_sel-date {
    display: block;
    cursor: pointer;
}

.dudp__calendar-header .dudp__sel-year:hover,
.dudp__calendar-header .dcp_sel-date:hover {
    color: #fff;
}

.dudp__calendar-header .dcp_sel-date {
    font-size: 30px;
}

.dudp__cal-container {
    position: relative;
    background-color: #fff;
    width: 284px;
    user-select: none;
    overflow: hidden;
    flex: 0 1 auto;
}

.dudp__cal-container .dudp__btn-cal-prev,
.dudp__cal-container .dudp__btn-cal-next {
    position: absolute;
    top: 0;
    left: 12px;
    display: block;
    text-align: center;
    font-size: 28px;
    line-height: 44px;
    width: 48px;
    height: 48px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 50%;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s ease, background-color 0.3s ease;
    z-index: 2;
}

.dudp__cal-container .dudp__btn-cal-next {
    left: auto;
    right: 12px;
}

.dudp__cal-container .dudp__btn-cal-prev:hover,
.dudp__cal-container .dudp__btn-cal-next:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.dudp__cal-container .dudp__btn-cal-prev:active,
.dudp__cal-container .dudp__btn-cal-next:active {
    background-color: rgba(0, 0, 0, 0.25);
}

.dudp__cal-container .dudp__btn-cal-prev.dp__hidden,
.dudp__cal-container .dudp__btn-cal-next.dp__hidden {
    opacity: 0;
    visibility: hidden;
}

.dudp__cal-container .dudp__calendar-views {
    width: 860px;
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.25s ease;
}

.dudp__cal-container .dudp__calendar-views.dp__animate-out {
    opacity: 0;
}

.dudp__cal-container .dudp__calendar-views.dp__hidden {
    visibility: hidden;
    opacity: 0;
}

.dudp__cal-container .dudp__calendar {
    display: inline-block;
    transform: translateX(-100%);
    padding: 0 16px 10px;
    transition: none;
}

.dudp__cal-container .dudp__calendar.dp__animate-left {
    transform: translateX(-200%);
    transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.dudp__cal-container .dudp__calendar.dp__animate-right {
    transform: translateX(0);
    transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.dudp__calendar .dudp__cal-month-year {
    text-align: center;
    line-height: 48px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.dudp__calendar .dudp__weekdays {
    display: flex;
    flex-direction: row;
}

.dudp__calendar .dudp__weekdays span {
    display: inline-block;
    flex: 0 1 auto;
    width: 36px;
    text-align: center;
    color: rgb(50, 50, 50);
    font-size: 11px;
    line-height: 20px;
    vertical-align: middle;
}

.dudp__calendar .dudp__cal-week {
    display: flex;
    flex-direction: row;
}

.dudp__calendar .dudp__cal-week .dudp__date {
    flex: 0 1 auto;
    display: block;
    text-decoration: none;
    text-align: center;
    color: rgb(50, 50, 50);
    width: 36px;
    line-height: 36px;
    height: 36px;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s;
}

.dudp__calendar .dudp__cal-week .dudp__pm,
.dudp__calendar .dudp__cal-week .dudp__nm {
    color: rgb(150, 150, 150);
}

.dudp__calendar .dudp__cal-week .dudp__date.disabled {
    cursor: not-allowed;
    color: rgb(200, 200, 200);
}

.dudp__calendar .dudp__cal-week .dudp__date:not(.disabled):not(.selected):hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.dudp__calendar .dudp__cal-week .dudp__date:not(.disabled):not(.selected):active {
    background-color: rgba(0, 0, 0, 0.25);
}

.dudp__calendar .dudp__cal-week .dudp__date.current {
    color: #1976d2;
    font-weight: 500;
}

.dudp__calendar .dudp__cal-week .dudp__date.pm,
.dudp__cal-dates .dudp__date.nm {
    color: rgb(150, 150, 150);
}

.dudp__calendar .dudp__cal-week .dudp__date.selected {
    font-weight: normal;
    color: #fff !important;
    background-color: #1976d2;
}

.dudp__cal-container .dudp__months-view {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 45px 16px;
    opacity: 1;
    transform: scale(1);
    transition: transform 0.25s ease, opacity 0.25s ease;
}

.dudp__cal-container .dudp__months-view.dp__animate-out {
    opacity: 0;
    transform: scale(1.3);
}

.dudp__cal-container .dudp__months-view.dp__hidden {
    display: none;
    opacity: 0;
}

.dudp__months-view .dudp__month-row {
    display: flex;
    flex-direction: row;
}

.dudp__months-view .dudp__month {
    flex: 0 1 auto;
    display: inline-block;
    line-height: 68px;
    min-width: 63px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.dudp__months-view .dudp__month:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.dudp__months-view .dudp__month:active {
    background-color: rgba(0, 0, 0, 0.25);
}

.dudp__months-view .dudp__month.selected {
    font-weight: 500;
    color: #1565c0;
}

.dudp__cal-container .dudp__years-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s ease;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dudp__cal-container .dudp__years-view.dp__hidden {
    opacity: 0;
    visibility: hidden;
}

.dudp__years-view .dudp__year {
    display: block;
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dudp__years-view .dudp__year:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.dudp__years-view .dudp__year:active {
    background-color: rgba(0, 0, 0, 0.25);
}

.dudp__years-view .dudp__year.selected {
    font-weight: 500;
    font-size: 24px;
    color: #1565c0;
}

.dudp__cal-container .dudp__buttons {
    padding: 0 10px 10px;
    text-align: right;
    text-align: right;
}

.dudp__buttons .dudp__button {
    display: inline-block;
    font-size: 14px;
    padding: 0 16px;
    min-width: 40px;
    text-align: center;
    text-transform: uppercase;
    line-height: 32px;
    font-weight: 500;
    cursor: pointer;
    color: #1565c0;
}

.dudp__buttons .dudp__button.clear {
    float: left;
    color: #b71c1c !important;
}

.dudp__buttons .dudp__button:hover {
    background-color: #e0e0e0;
}


/* Color theme */

.dudp__wrapper[data-theme="blue"] .dudp__calendar-header {
    background-color: #1565c0;
}

.dudp__wrapper[data-theme="blue"] .dudp__calendar .dudp__cal-week .dudp__date.current {
    color: #1976d2;
}

.dudp__wrapper[data-theme="blue"] .dudp__calendar .dudp__cal-week .dudp__date.selected {
    background-color: #1976d2;
}

.dudp__wrapper[data-theme="blue"] .dudp__calendar .dudp__cal-week .dudp__date.selected:hover {
    background-color: #0d47a1;
}

.dudp__wrapper[data-theme="blue"] .dudp__buttons .dudp__button,
.dudp__wrapper[data-theme="blue"] .dudp__months-view .dudp__month.selected,
.dudp__wrapper[data-theme="blue"] .dudp__years-view .dudp__year.selected {
    color: #0d47a1;
}

.dudp__wrapper[data-theme="red"] .dudp__calendar-header {
    background-color: #c62828;
}

.dudp__wrapper[data-theme="red"] .dudp__calendar .dudp__cal-week .dudp__date.current {
    color: #d32f2f;
}

.dudp__wrapper[data-theme="red"] .dudp__calendar .dudp__cal-week .dudp__date.selected {
    background-color: #d32f2f;
}

.dudp__wrapper[data-theme="red"] .dudp__calendar .dudp__cal-week .dudp__date.selected:hover {
    background-color: #b71c1c;
}

.dudp__wrapper[data-theme="red"] .dudp__buttons .dudp__button,
.dudp__wrapper[data-theme="red"] .dudp__months-view .dudp__month.selected,
.dudp__wrapper[data-theme="red"] .dudp__years-view .dudp__year.selected {
    color: #b71c1c;
}

.dudp__wrapper[data-theme="purple"] .dudp__calendar-header {
    background-color: #6a1b9a;
}

.dudp__wrapper[data-theme="purple"] .dudp__calendar .dudp__cal-week .dudp__date.current {
    color: #7b1fa2;
}

.dudp__wrapper[data-theme="purple"] .dudp__calendar .dudp__cal-week .dudp__date.selected {
    background-color: #7b1fa2;
}

.dudp__wrapper[data-theme="purple"] .dudp__calendar .dudp__cal-week .dudp__date.selected:hover {
    background-color: #4a148c;
}

.dudp__wrapper[data-theme="purple"] .dudp__buttons .dudp__button,
.dudp__wrapper[data-theme="purple"] .dudp__months-view .dudp__month.selected,
.dudp__wrapper[data-theme="purple"] .dudp__years-view .dudp__year.selected {
    color: #4a148c;
}

.dudp__wrapper[data-theme="indigo"] .dudp__calendar-header {
    background-color: #283593;
}

.dudp__wrapper[data-theme="indigo"] .dudp__calendar .dudp__cal-week .dudp__date.current {
    color: #303f9f;
}

.dudp__wrapper[data-theme="indigo"] .dudp__calendar .dudp__cal-week .dudp__date.selected {
    background-color: #303f9f;
}

.dudp__wrapper[data-theme="indigo"] .dudp__calendar .dudp__cal-week .dudp__date.selected:hover {
    background-color: #1a237e;
}

.dudp__wrapper[data-theme="indigo"] .dudp__buttons .dudp__button,
.dudp__wrapper[data-theme="indigo"] .dudp__months-view .dudp__month.selected,
.dudp__wrapper[data-theme="indigo"] .dudp__years-view .dudp__year.selected {
    color: #1a237e;
}

.dudp__wrapper[data-theme="teal"] .dudp__calendar-header {
    background-color: #00695c;
}

.dudp__wrapper[data-theme="teal"] .dudp__calendar .dudp__cal-week .dudp__date.current {
    color: #00796b;
}

.dudp__wrapper[data-theme="teal"] .dudp__calendar .dudp__cal-week .dudp__date.selected {
    background-color: #00796b;
}

.dudp__wrapper[data-theme="teal"] .dudp__calendar .dudp__cal-week .dudp__date.selected:hover {
    background-color: #004d40;
}

.dudp__wrapper[data-theme="teal"] .dudp__buttons .dudp__button,
.dudp__wrapper[data-theme="teal"] .dudp__months-view .dudp__month.selected,
.dudp__wrapper[data-theme="teal"] .dudp__years-view .dudp__year.selected {
    color: #004d40;
}

.dudp__wrapper[data-theme="green"] .dudp__calendar-header {
    background-color: #2e7d32;
}

.dudp__wrapper[data-theme="green"] .dudp__calendar .dudp__cal-week .dudp__date.current {
    color: #388e3c;
}

.dudp__wrapper[data-theme="green"] .dudp__calendar .dudp__cal-week .dudp__date.selected {
    background-color: #388e3c;
}

.dudp__wrapper[data-theme="green"] .dudp__calendar .dudp__cal-week .dudp__date.selected:hover {
    background-color: #1b5e20;
}

.dudp__wrapper[data-theme="green"] .dudp__buttons .dudp__button,
.dudp__wrapper[data-theme="green"] .dudp__months-view .dudp__month.selected,
.dudp__wrapper[data-theme="green"] .dudp__years-view .dudp__year.selected {
    color: #1b5e20;
}


/* End color theme */

body[datepicker-display="on"] {
    overflow: hidden;
}

@media (max-height: 414px) {
    .dudp__wrapper {
        flex-direction: row;
        bottom: 8px;
    }
    .dudp__calendar-header {
        width: 110px;
    }
}

@media (max-height: 320px) {
    .dudp__wrapper {
        bottom: 0;
    }
}


/*.calendar-wrapper[data-theme='blue'] .calendar-head-card {*/


/*background-color: #8ec165;*/


/*}*/


/*.calendar-wrapper[data-theme='blue'] .calendar-date-holder .calendar-dates .date.selected.current a {*/


/*color: #8ec165;*/


/*}*/


/*.calendar-wrapper[data-theme='blue'] .calendar-date-holder .calendar-dates .date.selected a:hover {*/


/*border-color: #8ec165;*/


/*}*/


/*.calendar-wrapper[data-theme='blue'] .calendar-date-holder .calendar-dates .date.selected a {*/


/*border-color: #8ec165;*/


/*}*/


/* smk error message */

.form-horizontal .has-feedback .smk-error-msg {
    display: none;
}


/* transparent overlay */

.transparent-overlay {
    top: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2500;
    background-color: rgba(22, 33, 40, 0.12);
    right: 0;
    /* display: none; */
}

.transparent-overlay .loading-img {
    width: 100px;
    margin: auto;
    text-align: center;
    margin-top: 290px;
}


/* profile photo */

.profile-photo-container {
    margin-right: 10px;
}

.profile-photo {
    height: 60px !important;
    width: 60px !important;
    object-fit: cover;
}


/* colors */

.color-1 {
    color: #d87976;
    /*color: #8EC165;*/
}

.color-2 {
    color: #d87976;
    /*color: #65BCC1;*/
}

.color-3 {
    color: #d87976;
    /*color: #9865C1;*/
}

.color-4 {
    color: #d87976;
    /*color: #C16A65;*/
}

.background-color-1 {
    color: #9aaaac;
    /*color: #8EC165;*/
}

.background-color-2 {
    color: #65bcc1;
}

.background-color-3 {
    color: #9865c1;
}

.background-color-4 {
    color: #c16a65;
}


/* info-table */

.table.table-info {
    border: none;
    border-right: solid 2px #eeeeef;
}

.table.table-info tr {}

.table.table-info tr td {
    border: 1px solid transparent;
}

.table.table-info tr td.title {
    font-weight: bold;
    color: #7c7170;
}


/* end info-table */


/* group-posting */

.panel-group-posting {
    margin-bottom: 0px;
}

.panel-group-posting .panel-heading {
    font-weight: bold;
}

.panel-group-posting .label.client-id {
    font-size: 12px;
}

.panel-group-posting .table {
    border: none;
}

.panel-group-posting .input-container {
    padding: 0 !important;
}

.panel-group-posting .input-container input {
    width: 180px;
    border: 0;
    height: 100%;
}


/* group-posting */


/* date-picker */

.calendar-wrapper[data-theme="blue"] .calendar-head-card {
    background-color: #65bd77;
}

.calendar-head-card .calendar-year,
.calendar-head-card .calendar-date-wrapper {
    color: rgb(255, 255, 255);
}

.date-picker {
    background-color: transparent !important;
    cursor: pointer !important;
}

.calendar-wrapper[data-theme="blue"] .calendar-date-holder .calendar-dates .date.selected.current a {
    color: #65bd77;
}

.calendar-wrapper[data-theme="blue"] .calendar-date-holder .calendar-dates .date.selected a:hover {
    border-color: #65bd77;
}

.calendar-wrapper[data-theme="blue"] .calendar-date-holder .calendar-dates .date.selected a {
    border-color: #65bd77;
}

.dudp__wrapper[data-theme="blue"] .dudp__calendar-header {
    background-color: #65bd77;
}

.dudp__wrapper[data-theme="blue"] .dudp__calendar .dudp__cal-week .dudp__date.selected:hover {
    background-color: #65bd77;
}

.dudp__wrapper[data-theme="blue"] .dudp__calendar .dudp__cal-week .dudp__date.selected {
    background-color: #65bd77 !important;
}

.dudp__button,
.dudp__wrapper[data-theme="blue"] .dudp__months-view .dudp__month.selected,
.dudp__wrapper[data-theme="blue"] .dudp__years-view .dudp__year.selected {
    color: #65bd77 !important;
}

.dudp__calendar-header {
    color: rgb(255, 255, 255);
}


/* end date-picker */


/* journal-entry-form */

.journal-entries-container {}

.journal-entries-container .title {
    font-size: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}

.journal-entries-container .title i {}

.journal-entries-container .journal-entry {
    border: solid 1px #eee;
    padding: 40px 15px 15px;
    margin-bottom: 15px;
    position: relative;
}

.journal-entries-container .journal-entry .btn-cancel {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 9px;
    font-size: 17px;
}

.journal-entries-container .total-amount {
    background-color: #f9f9f9;
}


/* end-journal-entry-form */


/* misc */

.margin-top--10 {
    margin-top: -10px;
}

.report .more-info {
    border: solid 1px #4cc0c1;
    color: #4cc0c1;
    padding: 0px 7px;
    border-radius: 50%;
    cursor: pointer;
    font-style: normal;
}

.report-table-container th i.more-info {
    color: #4cc0c1;
    cursor: pointer;
}

.photo-spec {
    float: left;
    margin-top: 21px;
    color: #d66565;
}

.modal-lock-screen {
    display: block;
    top: 0;
    background: #26313e;
}

.modal-lock-screen strong {
    font-size: 20px;
    color: #fff;
}

.modal-lock-screen .unlock-screen-form-container {
    text-align: center;
    padding: 50px;
}

.modal-lock-screen .unlock-screen-form-container img {
    border: solid 1px #ddd;
    margin-top: 100px;
    border-radius: 100%;
    background-color: #fff;
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin-bottom: 5px;
}

.margin-left-15 {
    margin-left: 15px;
}

.input-phone {}

.input-phone::before {
    content: "READ ME";
}

.input-phone::after {
    content: "happy day";
}

.smk-confirm-back {
    z-index: 2000;
}

.collapse-icon {
    border: 0 !important;
    padding: 2px 10px !important;
    margin-top: 10px;
    background-color: transparent !important;
    color: #a7a9c0;
}

.collapse-icon i {
    font-size: 25px;
    color: #ddd;
}

.bg-light .nav-primary ul.nav>li>a {
    overflow: hidden;
}

.bg-light .nav-primary ul.nav>li>a>i {
    color: #a4a4a4 !important;
}

.transaction-date {
    font-weight: bold;
    font-size: 11px;
    text-decoration: underline;
    margin-left: 8px;
}

.alert {
    font-size: 100%;
}

.transfer-arrow {}

.table-btns .btn {
    min-width: 75px;
}

.table-btns {
    margin-bottom: 10px;
}

.modal-open .modal {
    margin-top: 50px;
}

th {
    white-space: nowrap !important;
}

div.dt-buttons {
    float: left !important;
}

.table-responsive {
    overflow: auto;
}

.btn-more-option ul li a {
    cursor: pointer !important;
}

.ghost {
    color: transparent;
    position: absolute;
}

.btn-version {
    border: transparent;
    background-color: transparent;
    mso-border-shadow: 0;
}

.progress-bar {
    background-color: #65be77;
}

.dataTables_wrapper .td-input {
    padding: 0px !important;
}

.dataTables_wrapper .td-input-text {
    border: solid 1px transparent;
    border-radius: 0;
    margin: 0;
    background-color: transparent;
    padding: 0px 15px;
}

.td-input .dropdown-toggle {
    border: 0;
    background-color: transparent;
    box-shadow: none;
}

.segment-title {
    font-weight: bold;
    padding: 0px 20px;
}

.btn.btn-metro {
    border-radius: 0px;
}

.width-150 {
    width: 150px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-0 {
    margin-bottom: 0px;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

.text-align-middle {
    text-align: center;
}

.padding-15 {
    padding: 15px;
}

.clickable {
    cursor: pointer;
}

.input-group {
    width: 100%;
}

.preview-title {
    margin-bottom: 15px;
    margin-top: 15px;
    border-bottom: solid 1px #ddd;
    padding-bottom: 10px;
}

.wizard-btns {
    padding: 0px 7px 5px;
    margin-top: -10px;
}

#btn-datepicker {
    cursor: pointer;
}

.required-field:after {
    content: " *";
    color: #f25656;
    position: inherit;
}

.panel .cancel {
    float: right;
    cursor: pointer;
}

.new-user-form-container,
.new-bank-form-container,
.new-cheque-form-container,
.new-submission-form-container,
.new-branch-form-container {
    display: none;
}

.hidden {
    display: none;
}

.show {
    display: block;
}


/* schedule table */

.table-schedule .waived-container {
    position: relative;
}

.table-schedule .waived-container .waived {
    position: absolute;
    font-size: 10px;
    color: #e04939;
    top: -3px;
    right: 15px;
}

.table-schedule>tbody>tr>td.danger {
    background-color: #f2dede !important;
    border-right: solid 1px whitesmoke !important;
}

.table-schedule>tbody>tr>td.success {
    background-color: #dff0d8 !important;
    border-right: solid 1px whitesmoke !important;
}

.table-schedule>tbody>tr>td.warning {
    background-color: #ffffff !important;
    border-right: solid 1px whitesmoke !important;
}


/* info well */

.bc-info-well {
    padding: 15px;
    border: solid 1px #eee;
    background-color: #eee;
}

.bc-info-well ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
}

.bc-info-well ul li {
    margin: 10px;
    word-wrap: break-word;
    margin-right: 0;
}

.bc-info-well ul li strong {
    margin-right: 15px;
    word-wrap: normal;
}

.utility-btn-container {
    text-align: right;
}


/* smk-confirm */

.smk-confirm-back {
    background-color: #586471d1;
}

.smk-confirm {
    top: 25px !important;
}


/* Permission */

.tab-module {}

.tab-module .panel-body {
    padding: 0 15px;
}

.tab-module .panel-body .permission {}

.tab-module .panel-body .permission .checkbox i {
    margin: 0 5px 0 -2px;
}


/* End Permission */


/* Report Table */

.table.no-border {
    border: 0;
}

.table.no-border td,
.table.no-border th {
    border: 0;
}

.panel-body.report {
    padding: 0px 0px 0px;
    position: relative;
}

.report-table-container {
    overflow: auto;
    height: auto;
    padding-bottom: 10px;
}

.report-table-container table {
    margin: 0;
    border: 0;
}

.report-table-container table thead tr {}

.report-table-container .separator-border {
    border-right: solid 1px #ccc !important;
}

.separator-border-right {
    border-right: solid 1px #d9d9d9;
}

.report-table-container table thead tr th {
    white-space: nowrap;
}

.report-table-container table tbody tr td {
    white-space: nowrap;
}

.report .table td,
.report .table th {
    padding: 5px;
    font-size: 13px;
}


/* Scrolling table */

.containerr {
    margin: 150px auto;
}


/* End Report Table */


/* Dashboard */

.dashboard-charts .panel-default>.panel-heading {
    color: #d87976;
}

.dashboard-charts .today-stat .h3 {
    font-size: 20px;
}


/* End Dashboard */


/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/


/*==========  Mobile First Method  ==========*/


/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) {}


/* Extra Small Devices, Phones */

@media only screen and (min-width: 480px) {
    /* was 480px */
}


/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {
    .width-20percent {
        width: 20%;
    }
    .nav-xs>.vbox>.header,
    .nav-xs>.vbox>.footer {
        border-right: solid 1px #cfcfcf;
    }
    .nav-xs .nav-primary>ul>li>a span {
        color: #717171 !important;
    }
    .app .vbox>section {
        position: absolute;
    }
    section.wrapper {
        overflow: auto;
        height: -webkit-fill-available;
        padding-bottom: 50px;
        padding-left: 65px;
        padding-right: 65px;
        padding-top: 50px;
        /*position: absolute;
        overflow: auto;
        height: 820px;*/
    }
    .hbox.stretch.client {
        height: 820px !important;
    }
}


/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {}


/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {}


/****************************************************/


/* Custom, iPhone Retina */

@media only screen and (max-width: 320px) {}


/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {
    /* was 480px */
    .wizard-steps ul li {
        display: none;
    }
    .wizard-steps ul li.active {
        display: block;
    }
    .wizard .actions {
        position: fixed;
        bottom: 0px;
        left: 0;
        width: 100%;
        z-index: 20;
        background-color: #fff;
        padding: 10px;
        border-top: solid 1px #ddd;
    }
}


/* Small Devices, Tablets */

@media only screen and (max-width: 768px) {
    .m-full-width {
        width: 100%;
    }
    .table-responsive {
        border: none;
    }
    .table-info {
        border-right: transparent !important;
    }
    .table.table-info tr td {
        text-align: right;
        padding: 5px 0;
    }
    .table.table-info tr td.title {
        text-align: left;
    }
}


/* Medium Devices, Desktops */

@media only screen and (max-width: 992px) {}


/* Large Devices, Wide Screens */

@media only screen and (max-width: 1200px) {}

.table-scroll {
    position: relative;
    max-width: 100%;
    margin: auto;
    overflow: hidden;
    border: 1px solid #000;
}

.table-wrap {
    width: 100%;
    overflow: auto;
}

.table-scroll table {
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
}

.table-scroll th,
.table-scroll td {
    padding: 5px 10px;
    border: 1px solid #000;
    background: #fff;
    white-space: nowrap;
    vertical-align: top;
}

.table-scroll thead,
.table-scroll tfoot {
    background: #fff;
}

.clone {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.clone th,
.clone td {
    visibility: hidden;
}

.clone td,
.clone th {
    border-color: transparent;
}

.clone tbody th {
    visibility: visible;
}

.clone .fixed-side {
    border: 1px solid #000;
    background: #eee;
    visibility: visible;
}

.clone thead,
.clone tfoot {
    background: transparent;
}


/* Fixed table heaer */

.header-fixed tbody {
    display: block;
    max-height: 500px;
    overflow: overlay;
}

.header-fixed thead,
.header-fixed tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.header-fixed thead {
    width: 100%;
}

.header-fixed-spec tbody {
    display: block;
    max-height: 500px;
    overflow: overlay;
}

.header-fixed-spec thead,
.header-fixed-spec tbody tr {
    display: table;
    width: 3500px;
    table-layout: fixed;
}

.header-fixed-spec thead {
    width: 3500px;
}


/*********************************************************************************************/


/*html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.intro {
  max-width: 1280px;
  margin: 1em auto;
}*/

.table-scrolls {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    max-height: 500px;
}

.table-scrolls table {
    width: 100%;
    min-width: 1280px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
}

.table-wraps {
    position: relative;
}

.table-scrolls th,
.table-scrolls td {
    vertical-align: top;
    white-space: nowrap !important;
    font-size: 18px;
}

.table-scrolls thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

div.table-scrolls th:first-child,
div.table-scrolls td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    background: #f9f9f9;
}

div.table-scrolls thead th:first-child,
div.table-scrolls tfoot th:first-child {
    z-index: 5;
}


/*table-header-fit {*/


/*.table-header-fit table {
  width: 100%;
  margin: 20px auto;
  table-layout: auto;
}*/

.inner-table {
    width: 35%;
    white-space: nowrap;
    border-color: transparent;
    border-width: 0;
}

.left-border {
    border-left: 1px solid #e7e8f2;
}

.header-icon {
    color: #a7a9c0;
    margin: 13px;
}

.dashboard-tiles {
    background-color: #4e5864;
    color: #ffffff;
    border-radius: 6px;
    width: 193px;
    height: 182px;
    padding: 30px 50px 50px 50px;
}

.dashboard-icons {
    width: 50px;
    height: 50px;
    /*padding-top: 20px;*/
}

.dashboard-tiles-figures {
    font-weight: bold !important;
    font-size: 18px !important;
    padding-top: 5px !important;
}

.dashboard-tiles-title {
    font-size: 12px !important;
    padding-top: 5px !important;
    text-wrap: normal;
}

.dashboard-tab {
    width: 25%;
    border-radius: 5px 0px 0px 0px !important;
    border: 1px solid #cfcfcf !important;
    border-top: 0px !important;
    border-left: 0px !important;
    text-align: center !important;
}

.dashboard-tab.active {
    border-bottom: 0px !important;
    font-weight: bold;
}

.dashboard-panel {
    border-radius: 5px !important;
    border: 1px solid #cfcfcf !important;
}

.dashboard-download-icon {
    width: 15px !important;
    height: 15px !important;
}

.text-aquila-red {
    color: #f95959;
}

.arrow-scrolls {
    fill: #45dabd !important;
    color: #ffffff !important;
    width: 25px !important;
    height: 25px !important;
}

.single-data-circle {
    background-color: #ffffff;
    border-radius: 50% !important;
    border: 2px solid #45dabd;
    height: 200px;
    width: 200px;
    vertical-align: center;
    margin-top: 39px !important;
    margin-bottom: 40px !important;
}

.single-data-main-text {
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 26px;
}

.single-data-sub-text {}

.centralize-div {
    margin-top: 80px;
}

.panel-default>.custom-panel-background {
    background-color: #f8f9fd !important;
}

.sidebar-text {
    /* margin-top: -28px !important;
    margin-left: 45px !important; */
    font-size: 11px;
}

.dashboard-panel-item {
    height: 400px !important;
    background-color: #f8f9fd !important;
}

.dashboard-panel-item {
    height: 400px !important;
    background-color: #f8f9fd !important;
}

.dashboard-panel-item div.panel-heading {
    background-color: #f8f9fd !important;
}

.dashboard-panel-item div.panel-body {
    height: 359px !important;
    background-color: #f8f9fd !important;
}

.dashboard-tiles-mobile .slick-next {
    right: -10px !important;
}

.dashboard-tiles-mobile .slick-prev {
    left: -10px !important;
}


/*.slick-prev {
  left: -4px !important;
}

.slick-next {
  right: -4px !important;
}*/


/*}*/

.chart-size {
    width: 100% !important;
    min-height: 180px !important;
}

.chart-size-desktop {
    width: 100% !important;
    min-height: 250px !important;
}


/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) {
    .dashboard-panel-item {
        height: auto !important;
    }
    .dashboard-panel-item div.panel-body {
        /*height: auto !important;*/
    }
    .panel-body {
        /*height: auto !important;*/
    }
    .mobile-tablet-view {
        display: flex !important;
    }
    .desktop-view {
        display: none !important;
    }
    .chart-size {
        width: 100% !important;
    }
}


/* Extra Small Devices, Phones */

@media only screen and (min-width: 480px) {
    /* was 480px */
    .dashboard-panel-item {
        height: auto !important;
    }
    .dashboard-panel-item div.panel-body {
        height: auto !important;
    }
    .panel-body {
        height: auto !important;
    }
    .navbar-visibility {
        display: none !important;
    }
    .mobile-tablet-view {
        display: flex !important;
    }
    .desktop-view {
        display: none !important;
    }
}


/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {
    .dashboard-panel-item {
        height: auto !important;
    }
    .dashboard-panel-item div.panel-body {
        height: auto !important;
    }
    .panel-body {
        height: auto !important;
    }
    .width-20percent {
        width: 20%;
    }
    .nav-xs>.vbox>.header,
    .nav-xs>.vbox>.footer {
        border-right: solid 1px #cfcfcf;
    }
    .nav-xs .nav-primary>ul>li>a span {
        color: #717171 !important;
    }
    .app .vbox>section {
        position: absolute;
    }
    section.wrapper {
        overflow: auto;
        height: -webkit-fill-available;
        padding-bottom: 50px;
        padding-left: 65px;
        padding-right: 65px;
        padding-top: 50px;
        /*position: absolute;
    overflow: auto;
    height: 820px;*/
    }
    .hbox.stretch.client {
        height: 820px !important;
    }
    .navbar-visibility {
        display: none !important;
    }
    .mobile-tablet-view {
        display: flex !important;
    }
    .desktop-view {
        display: none !important;
    }
}


/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {
    .dashboard-panel-item {
        height: auto !important;
    }
    .dashboard-panel-item div.panel-body {
        height: auto !important;
    }
    .panel-body {
        height: auto !important;
    }
    .mobile-tablet-view {
        display: flex !important;
    }
    .desktop-view {
        display: none !important;
    }
    .navbar-visibility {
        display: none !important;
    }
}


/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {
    .dashboard-panel-item {
        height: auto !important;
    }
    .dashboard-panel-item div.panel-body {
        height: auto !important;
    }
    .panel-body {
        height: auto !important;
    }
    .mobile-tablet-view {
        display: none !important;
    }
    .desktop-view {
        display: block !important;
    }
    .navbar-visibility {
        display: none !important;
    }
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 10px;
    height: 10px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #090909;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}